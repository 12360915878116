import * as React from "react";

function SvgComponent(props) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width={27} height={40} viewBox="0 0 27 40" {...props}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="75px" height="75px" viewBox="0 0 75 75" enable-background="new 0 0 75 75" {...props}>  <image id="image0" width="75" height="75" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAIAAAC3LO29AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAABIAAAASABGyWs+AAAAB3RJTUUH5ggLDi0PUbP0iwAAAHd0RVh0UmF3IHByb2ZpbGUg
dHlwZSA4YmltAAo4YmltCiAgICAgIDQwCjM4NDI0OTRkMDQwNDAwMDAwMDAwMDAwMDM4NDI0OTRk
MDQyNTAwMDAwMDAwMDAxMGQ0MWQ4Y2Q5OGYwMGIyMDRlOTgwMDk5OAplY2Y4NDI3ZQqmU8OOAAAA
JXRFWHRSYXcgcHJvZmlsZSB0eXBlIGlwdGMACmlwdGMKICAgICAgIDAKwNb8ZgAAAAFvck5UAc+i
d5oAAAX9SURBVHja7dpbbBTXGcDx7ztn7rsGm6tl54JNCRYbKVDE0jRC3IIKqar2wW4gYKGCJYRU
qS+ofQCpqVTTqk8YEhQLJU9cFIkkTpARTaJIRpUaLLAMAmw3GHMxGFPjofbu7OzMnO/Lgwtt+kSz
i2DQ/F/3jLS/PWfOmV0tep4Hz3TiSb+BRJgIE2EifAZKhPEvEca/RBj/EmH8S4TxLxHGv0QY/xJh
/EuE8S8Rxr9EGP8S4f8ZMgMTIz/SaCZgiocQARBYIbGpS90UzPg/Fmb+LoaRpW6y7sCjfRpPWMgA
zKTphv9p5/33DhIpRgTmhy8bhmmYDgA+GI7A7B5qn/z4Q6Hr/xn5FAoJhWBCpZgIQVoXv7H/foaV
EiQIBSGgijRGb+DKRG8vcwCMoACYIdKsMz3mhR4EJPq3kbH8M6qVer1SAjlKpxlAAyikJDgWmGlp
6sr3NOYonZIgih3H6Pot+72DMmR2pGRdEYBthOkZqEtNmkQSQu/BJD89QuZI0yIG+KQTer6+vyCj
FYqGbhb/9rV3rc9p3BQWisVjxzyn0r49puXyEx9+5qxZpU51hIO3cHHG0jXhjucPvU/jI8ZPfi4z
DTIIGAWXlVnqKkUd+cgR/qCdojBUkQQEXceBC05Hh+l54E3Ijo/48iUlIiIF7pj/bhudOEFSURCh
aYihG8q7Z17qU2371b8mQZplX6UlCJlRapSbgNNd+ErG+tOfZ/xqi9AlAUnDkBXT2LIpndKErb+2
TK9rCOfWWL94w+nt1n+6cfrv/5De+CZMTIYvNaR/81t+fQNO3hP5CZb4iAfNo1fCKkVkVkKmaOYs
/cawOnM2em62Yl0jDg0T3cnwwiUOfDU5rtAOWYI7Rv39bNfCQG+x/xWhOabmsDG1D4MACAxpYcTI
5b0bS7sPidB0RMtb+XcOWX9s9V7OcGXKRkNmf+idPaf2/8WSQi1+2Vqy2NNC68svJj7/ytz+Szxy
BH73dmHlqz5FEAS1AKGu5VOpSiWRJQNDWTdULOX/NAgQgdINU8/lc6M3nWkVLM1CMZCzqx0Q+M/R
3ERO1taCY1Gg6Pb1yNDs2nnB2B0cd+WsmcuyP1rQ0NBx6q/374xRlDcqZwhNkyQIy/mgU9IcEoCB
IlcMj90SF/MLZuTU5nnwg2pN08z9bW2X/vHNvn37OIowjGzbKtbPl4pUoWDMnGNW1waRuuMVZhUD
ALBnTo+oQjABQ3l5UOpeygBCa+srvDOgnxsxPx22dnUXr97PCYCTnZ2HDr6bG7/n2FYYhf39lzEI
LSmddCry/RvXrkV+QQNI2zYRjd6+nTJNfAyHYUlCBjAk3sgHXXesSts2DK605FDBuuhP7zxx4vMv
vpRSNjY1nTx5csWKFY2NTStXrboyOHj69OlFmcy8urrtLS1z5s7tPX9+4cKF9fPn79mzR9d1fgyP
b6WcFoAATEDAEoAAJYMQZj7vrXh12ZKlS23bbmlpaW5udl13586d3d3dGzZs2LRpEwAcPnx4x44d
YRgWCoUDBw4sX768tbV1eHjYNM2yI7+/EBECxc9XyB9X+q5XDAjHIpprTC6pUtNmVdfNq6uqqlq7
du34+Hh1dfXw8PCuXbuy2ezIyEhzc/PmzZtXr17tum42m12/fv2aNWsAQCmFiGUXlrTTMIJG/OvF
FVUD3kXXn27SWwvMRekIACrSzs2bN1tbWzOZzNWrV7du3Xr8+PGmpqa+vr69e/eOjo5ms1ml1N27
dwHAdV0AIHos3xXl7t27v/fFCEAMFRJWVhuv18DPas0XbZkj0KX+wgvPne3picKwvb19cHDw6NGj
NTU127Zt27Jly9DQUFdXV01NTX19fSaTWbdu3cjIiO/7jY2NqVSKiBDLueWUdB5OxQDALBEVIwNL
ZCY2LEtKOTUtQggiEkIws1JK076zcPL5vG3bQgjf9x/HTlMG4UPnf3/yxAQMU/cVM0splVJCCAB4
qGVmRJzyT40pO6+cwqe25Le2+JcI418ijH+JMP4lwviXCONfIox/iTD+JcL4lwjjXyKMf4kw/n0L
0RDcvZBhURgAAABQZVhJZk1NACoAAAAIAAIBEgADAAAAAQABAACHaQAEAAAAAQAAACYAAAAAAAOg
AQADAAAAAQABAACgAgAEAAAAAQAAAfSgAwAEAAAAAQAAAfQAAAAAZbPgngAAACV0RVh0ZGF0ZTpj
cmVhdGUAMjAyMi0wOC0xMVQxNDo0NToxNSswMDowMJd39DoAAAAldEVYdGRhdGU6bW9kaWZ5ADIw
MjItMDgtMTFUMTQ6NDU6MTUrMDA6MDDmKkyGAAAAEXRFWHRleGlmOkNvbG9yU3BhY2UAMQ+bAkkA
AAASdEVYdGV4aWY6RXhpZk9mZnNldAAzOK24viMAAAAYdEVYdGV4aWY6UGl4ZWxYRGltZW5zaW9u
ADUwMEE76LEAAAAYdEVYdGV4aWY6UGl4ZWxZRGltZW5zaW9uADUwMNw0CccAAAAASUVORK5CYII=" />
</svg>

    // {/* </svg> */}
  );
}

export default SvgComponent;
